import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useGameControl } from './useGameControl';
import { ParticipantCard } from './ParticipantCard';

export const AdminGameControl = ({ gameId }) => {
    const { game, resetGame, setParticipantPlayer, setParticipantDead, setParticipantRole } = useGameControl(gameId);

    if (game == null) {
        return null;
    }

    const confirmResetGame = () => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm('Очистить игру?')) {
            resetGame();
        }
    };

    return (
        <Container className="mb-4">
            <Row className="mt-4">
                <Col className="d-flex align-items-center">
                    <h4>{game.title}</h4>
                    <a href={`/games/${gameId}/view`} target="_blank" className="ms-3">
                        Плашки
                    </a>
                    <Button className="ms-4" onClick={confirmResetGame}>
                        Очистить
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col className="d-flex flex-column align-items-start">
                    {game.participants.map((participant) => (
                        <ParticipantCard
                            key={participant.id}
                            participant={participant}
                            onChangePlayer={(player) => setParticipantPlayer(participant.id, player)}
                            onChangeDead={(dead) => setParticipantDead(participant.id, dead)}
                            onChangeRole={(role) => setParticipantRole(participant.id, role)}
                        />
                    ))}
                </Col>
            </Row>
        </Container>
    );
};
