import React from "react";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import { usePlayersStore } from "../hooks/usePlayersStore";

export const PlayerSelect = ({ player, setPlayer, isCreatable, ...rest }) => {
  const { players, createPlayer, getPlayerById } = usePlayersStore();

  const playerToOption = (player) => ({
    value: player.id,
    label: player.name,
  });

  const onPlayerChange = (option) => {
    if (option) {
      setPlayer(getPlayerById(option.value));
    } else {
      setPlayer(null);
    }
  };

  const onCreateOption = (name) => {
    setPlayer(createPlayer(name));
  };

  const Component = isCreatable ? CreatableSelect : Select;

  return (
    <Component
      value={player ? playerToOption(player) : null}
      options={players.map(playerToOption)}
      onChange={onPlayerChange}
      onCreateOption={onCreateOption}
      placeholder={"Выбрать игрока..."}
      formatCreateLabel={(inputValue) => `Добавить игрока "${inputValue}"`}
      {...rest}
    />
  );
};
