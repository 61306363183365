import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const GameTile = styled(Link)`
    width: 240px;
    height: 180px;
    padding: 10px 20px;
    border-radius: 8px;
    //border: 1px solid black;
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
    transition: all 0.1s ease;
    text-decoration: none;
    color: black;

    &:hover {
        background-color: #f5f5f5;
        cursor: pointer;
    }

    @media (max-width: 768px) {
        width: 100%;
        height: 100px;
    }
`;

export const GameTileTitle = styled.div`
    font-weight: 600;
    font-size: 20px;
`;

export const GameTileSubtitle = styled.div`
    font-weight: 400;
    font-size: 14px;
    color: #3d3d3d;
`;
