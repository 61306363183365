import { useDatabaseState } from './useDatabaseState';
import { ref, set, remove } from 'firebase/database';
import { useMemo } from 'react';
import { v4 as uuid4 } from 'uuid';
import { database } from '../../App';

export const useGamesStore = () => {
    const [games] = useDatabaseState('games', {});
    const sortedGames = useMemo(() => {
        return Object.values(games);
    }, [games]);

    const getGameById = (gameId) => {
        return games[gameId];
    };

    const deleteGame = (gameId) => {
        remove(ref(database, `/games/${gameId}`));
    };

    const createGame = (title, subtitle = '') => {
        const participants = [...Array(10).keys()].map((idx) => ({
            id: uuid4(),
            badge: (idx + 1).toString(),
            role: 'civilian',
            dead: false,
        }));
        const game = { id: uuid4(), title, subtitle, participants };
        set(ref(database, `/games/${game.id}`), game);
        return game;
    };

    const updateGame = (gameId, data) => {
        set(ref(database, `/games/${gameId}`), data);
    };

    return {
        games: sortedGames,
        createGame,
        updateGame,
        deleteGame,
        getGameById,
    };
};
