import React from 'react';
import { Container, Nav, Navbar as BaseNavbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Logo } from '../Logo';
import styled from 'styled-components';
import { useGamesStore } from '../hooks/useGamesStore';

export const Navbar = () => {
    let location = useLocation();
    const { games } = useGamesStore();

    if (location.pathname.includes('/view')) {
        return null;
    }

    return (
        <BaseNavbar bg="dark" variant="dark">
            <Container>
                <BaseNavbar.Brand as={Link} to="/">
                    <Logo />
                </BaseNavbar.Brand>
                <div className="d-flex me-md-auto">
                    <Nav>
                        <Nav.Link as={Link} to="/players">
                            Игроки
                        </Nav.Link>
                    </Nav>
                    <GameLinks>
                        {games.map((game) => (
                            <Nav>
                                <Nav.Link as={Link} to={`/games/${game.id}`}>
                                    {game.title}
                                </Nav.Link>
                            </Nav>
                        ))}
                    </GameLinks>
                </div>
            </Container>
        </BaseNavbar>
    );
};

const GameLinks = styled.div`
    display: flex;

    @media (max-width: 768px) {
        display: none;
    }
`;
