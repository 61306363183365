import { useDatabaseRef } from "./useDatabaseRef";
import { useObjectVal } from "react-firebase-hooks/database";
import { set } from "firebase/database";
import { useEffect } from "react";

export const useDatabaseState = (path, defaultValue, options) => {
  const valueRef = useDatabaseRef(path);
  const [value, loading, error] = useObjectVal(valueRef, options);

  const setValue = async (newValue) => {
    await set(valueRef, newValue);
  };

  return [value == null ? defaultValue : value, setValue];
};
