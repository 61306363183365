import React from 'react';
import { GameTile, GameTileSubtitle, GameTileTitle } from './styled';
import { Col, Container, Row } from 'react-bootstrap';
import { useGamesStore } from '../hooks/useGamesStore';
import { Link } from 'react-router-dom';

export const GamesListPage = () => {
    const { games, createGame } = useGamesStore();

    return (
        <Container className="mb-4">
            <Row className="mt-4">
                <Col>
                    <h4>Управление играми</h4>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col className="d-flex flex-wrap" style={{ gap: '20px' }}>
                    {/*<GameTile onClick={() => createGame('Новая игра')}>Новая игра</GameTile>*/}
                    {games.map((game) => (
                        <GameTile to={`/games/${game.id}`}>
                            <GameTileTitle>{game.title}</GameTileTitle>
                            {game.subtitle && <GameTileSubtitle className="mt-1">{game.subtitle}</GameTileSubtitle>}
                        </GameTile>
                    ))}
                </Col>
            </Row>
        </Container>
    );
};
