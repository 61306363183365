import React from "react";
import { Button } from "react-bootstrap";
import { ParticipantImage } from "../AdminGameControl/ParticipantCard";

export const AdminPlayerProfile = ({ player, onDelete, onAvatarUpdate }) => {
  const onDeleteConfirm = () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(`Удалить игрока ${player.name}?`)) {
      onDelete();
    }
  };

  return (
    <div>
      <div className="d-flex justify-content-between justify-content-md-start align-items-center">
        <span style={{ fontSize: 24, fontWeight: 600 }}>{player.name}</span>
        <Button onClick={onDeleteConfirm} variant={"danger"} className="mx-3">
          Удалить игрока
        </Button>
      </div>
      <div className="mt-4">
        <ParticipantImage key={player.avatar} src={player.avatar} />
      </div>
      <input
        type="file"
        accept="image/*"
        onChange={(e) => onAvatarUpdate(e.target.files[0])}
        className="mt-3"
      />
    </div>
  );
};
