import React from "react";
import logo from "../../assets/logo.png";

export const Logo = ({ width = 150 }) => {
  return (
    <div style={{ width }}>
      <img src={logo} alt="Eternity" />
    </div>
  );
};
