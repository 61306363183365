import React from 'react';
import styled from 'styled-components';
import deadMarker from '../../../assets/close.png';

export const DeadPicker = ({ dead, onChangeDead, className }) => {
    return (
        <RoleBox className={className} onClick={() => onChangeDead(!dead)}>
            {dead && <img src={deadMarker} />}
        </RoleBox>
    );
};

const RoleBox = styled.div`
    cursor: pointer;
    height: 38px;
    flex-basis: 38px;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    font-weight: 700;
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
    color: ${({ black }) => (black ? '#000' : '#c92f2f')};
    user-select: none;
    transition: background-color 0.1s ease;

    &:hover {
        background-color: #f6f6f6;
    }

    // &:active {
    //     background-color: #f3e4d4;
    //     // background-color: ${({ nextBlack }) => (nextBlack ? 'rgba(0, 0, 0, 0.5)' : 'rgba(201, 47, 47, 0.5)')};
    //     transition: none;
    // }
`;

const DeadMarker = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 4px;
    background-color: #c09959;
`;
