import { Route, Routes } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { GameControlPage } from './components/AdminGameControl/GameControlPage';
import { getDatabase } from 'firebase/database';
import { AdminPlayers } from './components/AdminPlayers';
import { Navbar } from './components/Navbar';
import { ParticipantsSourcePage } from './components/ParticipantsSourcePage';
import { GamesListPage } from './components/GamesListPage';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: 'AIzaSyAbV2jOLG3HYA9_L6-m2Gn3t8TrhU1r8eA',
    authDomain: 'blackfish-mafia.firebaseapp.com',
    databaseURL: 'https://blackfish-mafia-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'blackfish-mafia',
    storageBucket: 'blackfish-mafia.appspot.com',
    messagingSenderId: '209641756335',
    appId: '1:209641756335:web:4f4d27497f05cd3861a65a',
};

const firebaseApp = initializeApp(firebaseConfig);
export const database = getDatabase(firebaseApp);
export const storage = getStorage(firebaseApp);

function App() {
    return (
        <BrowserRouter>
            <Navbar />
            <Routes>
                <Route exact path={'/'} element={<GamesListPage />} />
                <Route exact path={'/players'} element={<AdminPlayers />} />
                <Route exact path={'/games/:gameId'} element={<GameControlPage />} />
                <Route exact path={'/games/:gameId/view'} element={<ParticipantsSourcePage />} />
                {/*<Route*/}
                {/*  exact*/}
                {/*  path={"/admin/games/:gameId"}*/}
                {/*  element={<AdminGameControl />}*/}
                {/*/>*/}
                {/*<Route*/}
                {/*  exact*/}
                {/*  path={"/games/:gameId/participants/:variant"}*/}
                {/*  element={<ParticipantsSourcePage />}*/}
                {/*/>*/}
            </Routes>
        </BrowserRouter>
    );
}

export default App;
