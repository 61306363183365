import produce from 'immer';
import { useGamesStore } from '../hooks/useGamesStore';

export const useGameControl = (gameId) => {
    const { updateGame, getGameById } = useGamesStore();
    const game = getGameById(gameId);

    const updateParticipant = (id, changes) => {
        updateGame(
            gameId,
            produce(game, (draft) => {
                const index = draft.participants.findIndex((participant) => participant.id === id);
                if (index !== -1) {
                    draft.participants[index] = {
                        ...draft.participants[index],
                        ...changes,
                    };
                }
            }),
        );
    };

    const setParticipantPlayer = (id, player) => {
        updateParticipant(id, { playerId: player.id });
    };

    const setParticipantRole = (id, role) => {
        updateParticipant(id, { role });
    };

    const setParticipantDead = (id, dead) => {
        updateParticipant(id, { dead });
    };

    const resetGame = () => {
        updateGame(gameId, {
            ...game,
            participants: game.participants.map((participant) => ({
                ...participant,
                dead: false,
                role: 'civilian',
                playerId: null,
            })),
        });
    };

    return {
        game,
        resetGame,
        setParticipantPlayer,
        setParticipantRole,
        setParticipantDead,
    };
};
