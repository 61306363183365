import React, { useState } from "react";

import { AdminPlayerProfile } from "./AdminPlayerProfile";
import { Container, Row, Col } from "react-bootstrap";
import { PlayerSelect } from "../PlayerSelect";
import { usePlayersStore } from "../hooks/usePlayersStore";

export const AdminPlayers = () => {
  const { deletePlayer, updatePlayerAvatar } = usePlayersStore();
  const [player, setPlayer] = useState(null);

  const onDeletePlayer = (playerId) => {
    deletePlayer(playerId);
    setPlayer(null);
  };

  const onUpdatePlayerAvatar = async (playerId, avatar) => {
    const updatedPlayer = await updatePlayerAvatar(playerId, avatar);
    setPlayer(updatedPlayer);
  };

  return (
    <Container>
      <Row className="mt-4">
        <Col>
          <h4>Управление игроками</h4>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <PlayerSelect
            player={player}
            setPlayer={setPlayer}
            isCreatable
            isClearable
          />
        </Col>
      </Row>
      {player && (
        <Row className="mt-4">
          <Col>
            <AdminPlayerProfile
              player={player}
              onDelete={() => onDeletePlayer(player.id)}
              onAvatarUpdate={(avatar) =>
                onUpdatePlayerAvatar(player.id, avatar)
              }
            />
          </Col>
        </Row>
      )}
    </Container>
  );
};
