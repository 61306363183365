import React from 'react';
import styled, { css } from 'styled-components';
import { PlayerSelect } from '../PlayerSelect';
// import Select from 'react-select';
import { usePlayersStore } from '../hooks/usePlayersStore';
import { RolePicker } from './RolePicker';
import { DeadPicker } from './DeadPicker';

export const ParticipantCard = ({ participant, onChangePlayer, onChangeDead, onChangeRole }) => {
    const { getPlayerById } = usePlayersStore();
    // const roleOptions = [
    //     { value: 'civilian', label: 'Мирный' },
    //     { value: 'sheriff', label: 'Шериф' },
    //     { value: 'mafia', label: 'Мафия' },
    //     { value: 'godfather', label: 'Дон' },
    // ];
    // const roleValue = roleOptions.find((option) => option.value === participant.role);
    const player = getPlayerById(participant.playerId);

    return (
        <CardWrapper className="mx-3 mt-3">
            <div className="d-flex align-items-center w-100">
                <span className="text-start fw-bold" style={{ width: 30 }}>
                    {participant.badge}
                </span>
                <PlayerSelect player={player} setPlayer={onChangePlayer} isSearchable={false} className="w-100" />
                {/*<Select*/}
                {/*    value={roleValue}*/}
                {/*    options={roleOptions}*/}
                {/*    onChange={(option) => onChangeRole(option.value)}*/}
                {/*    isSearchable={false}*/}
                {/*    className="w-100 ms-2"*/}
                {/*/>*/}
                <RolePicker role={participant.role} onChangeRole={onChangeRole} className="ms-3" />
                <DeadPicker dead={participant.dead} onChangeDead={onChangeDead} className="ms-3 me-3" />
                {/*<div className="d-flex align-items-center ms-3 me-3">*/}
                {/*    <input*/}
                {/*        type="checkbox"*/}
                {/*        checked={participant.dead}*/}
                {/*        onChange={(e) => onChangeDead(e.target.checked)}*/}
                {/*    />*/}
                {/*</div>*/}
            </div>
        </CardWrapper>
    );
};

const CardWrapper = styled.div`
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: start;
`;

export const ParticipantImage = styled.img`
    width: 120px;
    height: 120px;
    border-radius: 12px;
    object-fit: cover;
    z-index: 1;

    ${({ dead }) =>
        dead &&
        css`
            filter: grayscale(100%);
        `}
`;
