import React from 'react';
import { useGameControl } from '../../AdminGameControl/useGameControl';
import styled, { css } from 'styled-components';
import donIcon from '../../../assets/don.png';
import mafiaIcon from '../../../assets/mafia.png';
import sheriffIcon from '../../../assets/sheriff2.png';
import flag from '../../../assets/flag.png';
import { usePlayersStore } from '../../hooks/usePlayersStore';

export const UkraineVariant = ({ gameId }) => {
    const { game } = useGameControl(gameId);
    const { getPlayerById } = usePlayersStore();

    if (game == null) {
        return null;
    }

    return (
        <div className="d-flex" style={{ flexWrap: 'wrap', margin: 40, alignItems: 'end' }}>
            {game.participants.map((participant) => {
                const roleIcon = {
                    civilian: null,
                    sheriff: sheriffIcon,
                    mafia: mafiaIcon,
                    godfather: donIcon,
                }[participant.role];

                const player = getPlayerById(participant.playerId);

                return (
                    <ParticipantAvatar key={participant.id} dead={participant.dead}>
                        {roleIcon && <RoleIcon src={roleIcon} />}
                        {player && (
                            <>
                                {/*<Flag src={flag} dead={participant.dead} />*/}
                                <ParticipantImage key={player.id} dead={participant.dead} src={player.avatar} />
                                <ParticipantFooter black={['mafia', 'godfather'].includes(participant.role)}>
                                    <div className="mb-1 d-flex">
                                        <span style={{ margin: '0 5px' }}>{participant.badge}</span>
                                        <FooterDelimiter />
                                        <span style={{ margin: '0 5px', fontWeight: 500 }}>{player.name}</span>
                                    </div>
                                    <FooterBorder black={['mafia', 'godfather'].includes(participant.role)} />
                                </ParticipantFooter>
                            </>
                        )}
                    </ParticipantAvatar>
                );
            })}
        </div>
    );
};

const ParticipantAvatar = styled.div`
    position: relative;
    width: 100px;
    height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 1.2rem;
    transition: all 0.5s ease;
    background-color: #040e1e;
    border-radius: 6px;
    //overflow: hidden;

    ${({ dead }) =>
        dead &&
        css`
            height: 85px;
            //transform: translateY(1rem);
        `}
`;

const Flag = styled.img`
    position: absolute;
    z-index: 2;
    width: 60px;
    left: -6px;
    top: -6px;

    ${({ dead }) =>
        dead &&
        css`
            filter: grayscale(100%);
        `}
`;

const ParticipantImage = styled.img`
    width: 100px;
    height: 100%;
    border-radius: 6px;
    object-fit: cover;
    z-index: 1;

    ${({ dead }) =>
        dead &&
        css`
            filter: brightness(60%);
        `}
`;

const FooterDelimiter = styled.div`
    width: 1px;
    height: 15px;
    background-color: white;
    opacity: 40%;
`;

const ParticipantFooter = styled.div`
    height: 45px;
    width: 100%;
    //background-color: ${({ black }) => (black ? '#000' : '#dc1f1f')};
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
    color: white;
    font-weight: 700;
    position: absolute;
    z-index: 2;
    bottom: 0;
    border-radius: 0 0 6px 6px;
    font-size: 11px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    overflow: hidden;
`;

const FooterBorder = styled.div`
    width: 100%;
    height: 5px;
    //background-color: #c09959;
    background-color: ${({ black }) => (black ? '#000' : '#dc1f1f')};
`;

const ParticipantBadge = styled.div`
    position: absolute;
    color: white;
    background-color: ${({ dead }) => (dead ? '#4a4a4a' : 'darkred')};
    width: 1.75rem;
    height: 1.75rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 12px 0px rgb(0 0 0 / 75%);
    top: -5px;
    left: 0;
    transition: background-color 0.5s;
    z-index: 2;
`;

const RoleIcon = styled.img`
    width: 2rem;
    position: absolute;
    z-index: 2;
    top: -1.5rem;
    right: 0.3rem;
`;
