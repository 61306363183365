import { useDatabaseState } from "./useDatabaseState";
import { ref, set, remove } from "firebase/database";
import { useMemo } from "react";
import { v4 as uuid4 } from "uuid";
import { database, storage } from "../../App";
import {
  ref as sRef,
  uploadBytes, 
  getDownloadURL,
} from "firebase/storage";

export const usePlayersStore = () => {
  const [players] = useDatabaseState("players", {});
  const sortedPlayers = useMemo(() => {
    const result = Object.values(players);
    result.sort((a, b) =>
      a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
    );
    return result;
  }, [players]);

  const getPlayerById = (playerId) => {
    return players[playerId];
  };

  const getPlayerByName = (name) => {
    return Object.values(players).find((player) => player.name === name);
  };

  const deletePlayer = (playerId) => {
    remove(ref(database, `/players/${playerId}`));
  };

  const createPlayer = (name) => {
    const player = { id: uuid4(), name };
    set(ref(database, `/players/${player.id}`), player);
    return player;
  };

  const updatePlayer = (playerId, data) => {
    set(ref(database, `/players/${playerId}`), data);
    return data;
  }

  const uploadStorageFile = async (file, filePath) => {
    const storageRef = sRef(storage, filePath);
    const uploadedAvatar = await uploadBytes(storageRef, file);
    return await getDownloadURL(storageRef);
  }

  const updatePlayerAvatar = async (playerId, avatar) => {
    const avatarUrl = await uploadStorageFile(avatar, `/avatars/${playerId}/${avatar.name}`);
    const player = getPlayerById(playerId);
    return updatePlayer(playerId, {
      ...player,
      avatar: avatarUrl,
    });
  }

  return {
    players: sortedPlayers,
    deletePlayer,
    createPlayer,
    updatePlayerAvatar,
    getPlayerById,
    getPlayerByName,
  };
};
