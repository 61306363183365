import React from 'react';
import styled from 'styled-components';

export const RolePicker = ({ role, onChangeRole, className }) => {
    const symbol = {
        mafia: 'М',
        godfather: 'Д',
        sheriff: 'Ш',
        civilian: '',
    }[role];

    const nextRole = {
        civilian: 'mafia',
        mafia: 'godfather',
        godfather: 'sheriff',
        sheriff: 'civilian',
    }[role];

    return (
        <RoleBox
            black={['mafia', 'godfather'].includes(role)}
            nextBlack={['mafia', 'godfather'].includes(nextRole)}
            className={className}
            onClick={() => onChangeRole(nextRole)}
            unselectable="on"
            onselectstart="return false;"
            onmousedown="return false;"
        >
            {symbol}
        </RoleBox>
    );
};

const RoleBox = styled.div`
    cursor: pointer;
    height: 38px;
    flex-basis: 38px;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    font-weight: 700;
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
    color: ${({ black }) => (black ? '#000' : '#c92f2f')};
    user-select: none;
    transition: background-color 0.1s ease;

    &:hover {
        background-color: #f6f6f6;
    }

    // &:active {
    //     background-color: #f3e4d4;
    //     // background-color: ${({ nextBlack }) => (nextBlack ? 'rgba(0, 0, 0, 0.5)' : 'rgba(201, 47, 47, 0.5)')};
    //     transition: none;
    // }
`;
